import Link from "next/link"

import { handleUserEventClick } from "@app/shared/utils/tracking/userEvent"

import { pageButton } from "./style.css"

interface Props {
  children: React.ReactNode
  disabled: boolean
  href: string
}

export const PageButton: React.FC<Props> = ({ children, disabled, href }) => (
  <Link
    aria-disabled={disabled}
    aria-label={"page button"}
    className={pageButton({ disabled })}
    data-testid={disabled ? "pageButtonDisabled" : "pageButton"}
    href={href}
    onClick={
      disabled
        ? (event) => {
            event.preventDefault()
            handleUserEventClick("ScrollArticles")
          }
        : undefined
    }
  >
    {children}
  </Link>
)
